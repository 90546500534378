import React, { useState } from "react";
import { useStoreContext } from "../../../../../Store";
import { FieldLocation } from "./types";
import { UPDATE_DEFAULT_MAPPINGS, UPDATE_SOURCE_HEADER, UPDATE_TARGET_HEADER } from "../../../actionTypes";
import { Grid, Select, SelectItem, TextField, Checkbox, Button } from "@platform-ui/design-system";

export const MappingFields: React.FC<{
  sourceHeader: string;
  targetHeader: string;
  rows: any[];
  location: FieldLocation;
  isReadonly?: boolean;
}> = ({ sourceHeader, targetHeader, rows, location, isReadonly }) => {
  const { dispatch } = useStoreContext();
  const [fieldMappingDefaults, updateFieldMappingDefaults] = useState(JSON.stringify(rows, null, 4) || JSON.stringify([]));
  const [invalidDefaultMappings, setInvalidDefaultMappings] = useState(false);

  const handleSourceHeaderUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UPDATE_SOURCE_HEADER,
      payload: { ...location, sourceHeader: event.target.value }
    });
  };

  const handleTargetHeaderUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: UPDATE_TARGET_HEADER,
      payload: { ...location, targetHeader: event.target.value }
    });
  };

  interface FieldDefinition {
    type: string;
    label: string;
    value: string | number | boolean;
  }
  
  interface MappingRow {
    type: string;
    rowId: number;
    sourceField: FieldDefinition;
    targetField: FieldDefinition;
  }
  
  const handleDefaultMappingsUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    updateFieldMappingDefaults(input);
  
    try {
      // Handle empty input
      if (!input.trim()) {
        dispatch({
          type: UPDATE_DEFAULT_MAPPINGS,
          payload: { ...location, rows: [] }
        });
        setInvalidDefaultMappings(false);
        return;
      }
  
      const parsedJson = JSON.parse(input);
  
      // Validate array structure
      if (!Array.isArray(parsedJson)) {
        setInvalidDefaultMappings(true);
        return;
      }
  
      // Validate each mapping row
      const isValid = parsedJson.every((item: MappingRow, index) => {
        // Check basic structure
        if (
          typeof item !== 'object' ||
          !('type' in item) ||
          !('rowId' in item) ||
          !('sourceField' in item) ||
          !('targetField' in item)
        ) {
          return false;
        }
  
        // Validate rowId
        if (typeof item.rowId !== 'number' || item.rowId !== index) {
          return false;
        }
  
        // Validate type
        if (typeof item.type !== 'string') {
          return false;
        }
  
        // Validate source and target fields
        const validateField = (field: FieldDefinition) => {
          if (!field || typeof field !== 'object') return false;
          
          if (!('type' in field) || !('label' in field) || !('value' in field)) {
            return false;
          }
  
          // Validate type-specific value formats
          switch (field.type) {
            case 'string':
              return typeof field.value === 'string';
            case 'number':
              return typeof field.value === 'number';
            case 'boolean':
              return typeof field.value === 'string' || typeof field.value === 'boolean';
            default:
              return false;
          }
        };
  
        return validateField(item.sourceField) && validateField(item.targetField);
      });
  
      if (!isValid) {
        setInvalidDefaultMappings(true);
        return;
      }
  
      // If all validation passes, update the state
      dispatch({
        type: UPDATE_DEFAULT_MAPPINGS,
        payload: { ...location, rows: input }
      });
      setInvalidDefaultMappings(false);
  
    } catch (e) {
      // Invalid JSON syntax
      setInvalidDefaultMappings(true);
    }
  };
return (
    <>
      <Grid item>
        <TextField
          value={sourceHeader || ''}
          label={isReadonly ? 'Source Header' : 'Windmill Path for Source field'}
          required
          dsOnChange={handleSourceHeaderUpdate}
        />
      </Grid>
      <Grid item>
        <TextField
          value={targetHeader || ''}
          label={isReadonly ? 'Target Header' : 'Windmill Path for Source field'}
          required
          dsOnChange={handleTargetHeaderUpdate}
        />
      </Grid>
      <Grid item>
        <TextField
          value={fieldMappingDefaults}
          error={invalidDefaultMappings}
          label='Default value'
          required
          dsOnChange={handleDefaultMappingsUpdate}
          helperText={isReadonly ? 'Format: [{"sourceField": "srcf", "targetField": "tgtf"}]' : 'Format: [{"sourceField": {"type":"number","label":"42","value":42}, "targetField": {"type":"number","label":"42","value":42}}]'}
          multiline
          rows={4}
          maxRows={5}
          wrap='hard'
        />
      </Grid>
    </>
  );
};