import { Card, Grid, TextField } from "@platform-ui/design-system";
import React, { useState, useEffect, Dispatch } from 'react';
import { useStoreContext, Action } from "../../../../Store";
import { IntegrationAppFormState } from "../../state";
import EditorBlock from "./components/EditorBlock";
import { UPDATE_DESCRIPTION_TRANSLATION, UPDATE_TAB_DESCRIPTION_TRANSLATION, UPDATE_TAB_TITLE_TRANSLATION, UPDATE_TITLE_TRANSLATION } from "../../actionTypes";
import LocaleBlock from "./components/LocaleBlock";
import GridTile from "../../../../GridView/GridTile";
import Connect from "../../../../Connect/Connect";

function LocaleTab(props: any) {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };
  const locale = props.locale;
  // const tabList = state.ui_layout['tabs'].map((tab) => Object.keys(tab)[0])

  function toTitle(text) {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  const [titleInputValue, setTitleInputValue] = useState(state.title_translations[locale]?.title || '');
  const [descriptionInputValue, setDescriptionInputValue] = useState(state.description_translations[locale]?.short || '');

  const updateTitleTranslation = (updatedTitle: string) => {
    const newTitleTranslations = {
      ...state.title_translations,
      [locale]: {
        ...state.title_translations[locale],
        title: updatedTitle,
      },
    };
    dispatch({
      type: UPDATE_TITLE_TRANSLATION,
      payload: { title_translations: newTitleTranslations }
    });
  };

  const updateDescriptionTranslation = (updatedDescription: string) => {
    const newDescriptionTranslations = {
      ...state.description_translations,
      [locale]: {
        ...state.description_translations[locale],
        short: updatedDescription,
      },
    };
    dispatch({
      type: UPDATE_DESCRIPTION_TRANSLATION,
      payload: { description_translations: newDescriptionTranslations }
    });
  };

  useEffect(() => {
    setTitleInputValue(state.title_translations[locale]?.title || '');
    setDescriptionInputValue(state.description_translations[locale]?.short || '');
  }, [locale]);

  useEffect(() => {
    const titleTimer = setTimeout(() => {
      updateTitleTranslation(titleInputValue);
    }, 500);

    const descriptionTimer = setTimeout(() => {
      updateDescriptionTranslation(descriptionInputValue);
    }, 500);

    return () => {
      clearTimeout(titleTimer);
      clearTimeout(descriptionTimer);
    };
  }, [titleInputValue, descriptionInputValue]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card id="" header='Overview' titleBar body={
          <Grid container>
            <Grid item>
              <TextField
                label="Title"
                placeholder="Enter Title"
                value={titleInputValue || ''}
                dsOnChange={(e) => setTitleInputValue(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                multiline
                maxRows={3}
                rows={2}
                label="Description"
                placeholder="Enter Description"
                value={descriptionInputValue || ''}
                dsOnChange={(e) => setDescriptionInputValue(e.target.value)}
              />
            </Grid>
            {/* NOTE(Xander): Disabled because it's laggy
            <Grid container item>
              <GridTile 
                integrationView={true}
                useElevationTiles={false}
                icon={state.icon || Connect.public_path() + '/logo_zuora.png'}
                header={state.title_translations[locale]?.title}
                status='Active'
                surtitle={state.description_translations[locale]?.short}
                subHeader={[state.meta['product'], state.meta['category']]}
              />
            </Grid> */}
            <Grid item>
              <EditorBlock locale={locale} />
            </Grid>
          </Grid>
        }
        />
      </Grid>
      {state.ui_layout['tabs'].map((tab, tabIndex) => {
        const tabName = Object.keys(tab)[0]
        return (
          <Grid item xs={12}>
            <Card id="" header={toTitle(tabName)} titleBar
              body={
                <>
                  <Grid container direction={'row'}>
                    <Grid item xs={4}>
                      <TextField
                        label='Tab Name'
                        value={tab[tabName]?.['titleTranslations']?.[locale]?.['tabName'] || ''}
                        dsOnChange={(event) => {
                          dispatch({
                            type: UPDATE_TAB_TITLE_TRANSLATION,
                            payload: {
                              tabIndex,
                              tab: tabName,
                              locale,
                              key: 'tabName',
                              value: event.target.value
                            }
                          });
                        }}
                        required
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label='Tab Card Header'
                        value={tab[tabName]?.['titleTranslations']?.[locale]?.['cardHeader'] || ''}
                        dsOnChange={(event) => {
                          dispatch({
                            type: UPDATE_TAB_TITLE_TRANSLATION,
                            payload: {
                              tabIndex,
                              tab: tabName,
                              locale,
                              key: 'cardHeader',
                              value: event.target.value
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label='Tab Description'
                        value={tab[tabName]?.['descriptionTranslations']?.[locale]?.['description'] || ''}
                        dsOnChange={(event) => {
                          dispatch({
                            type: UPDATE_TAB_DESCRIPTION_TRANSLATION,
                            payload: {
                              tabIndex,
                              tab: tabName,
                              locale,
                              key: 'description',
                              value: event.target.value
                            }
                          });
                        }}
                        required
                      />
                    </Grid>
                  </Grid>
                  {
                    (tab[tabName]?.['fieldGroups'] || []).map((fieldGroup, fieldGroupIndex) => {
                      return (
                        <LocaleBlock fieldGroup={fieldGroup} fieldGroupIndex={fieldGroupIndex} tabName={tabName} tabIndex={tabIndex} locale={locale} />
                      );
                    })
                  }
                </>
              }
            />
          </Grid>
        )
      })}
    </Grid>
  );
}

export default LocaleTab
