import { Grid, Select, SelectItem, TextField, Checkbox, Button } from "@platform-ui/design-system";
import React, { useState } from "react";
import { useStoreContext } from "../../../../../Store";
import { 
  UPDATE_FIELD_TYPE, 
  UPDATE_FIELD_NAME, 
  TOGGLE_FIELD_REQUIRED, 
  TOGGLE_FIELD_READONLY, 
  DELETE_FIELD, 
  UPDATE_BUTTON_ONCLICK,
  UPDATE_DEFAULT_VALUE, 
} from "../../../actionTypes";
import { FieldTypes, Field, FieldLocation, FIELD_TYPE_OPTIONS } from "./types";
import { MappingFields } from "./MappingFields";

interface Props {
  field: Field;
  location: FieldLocation;
}

export const FieldBlock: React.FC<Props> = ({ field, location }) => {
  const { dispatch } = useStoreContext();
  const [useDefaultValue, setUseDefaultValue] = useState(field.defaultValue != null);
  const allowsDefault = [FieldTypes.TEXT, FieldTypes.TEXTAREA, FieldTypes.DATEPICKER, FieldTypes.CHECKBOX];

  const dispatchFieldUpdate = (type: string, additionalPayload = {}) => {
    dispatch({
      type,
      payload: { ...location, ...additionalPayload }
    });
  };

  // Updated to handle the correct event type from the Select component
  const handleFieldTypeChange = (event: any, child: React.ReactNode) => {
    const newType = event.target.value;
    dispatchFieldUpdate(UPDATE_FIELD_TYPE, {
      type: newType,
      required: newType === FieldTypes.CHECKBOX ? false : field.required
    });
  };

  const handleFieldNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchFieldUpdate(UPDATE_FIELD_NAME, { name: event.target.value });
  };

  const handleRequiredToggle = () => {
    dispatchFieldUpdate(TOGGLE_FIELD_REQUIRED, {
      type: field.fieldType,
      required: !field.required
    });
  };

  const handleReadonlyToggle = () => {
    dispatchFieldUpdate(TOGGLE_FIELD_READONLY, {
      type: field.fieldType,
      readonly: !field.readonly
    });
  };

  const handleDelete = () => {
    dispatchFieldUpdate(DELETE_FIELD);
  };

  const handleOnClickUpdate = (value: string) => {
    dispatchFieldUpdate(UPDATE_BUTTON_ONCLICK, { onClick: value });
  };

  const handleDefaultValueUpdate = (value: string) => {
    dispatchFieldUpdate(UPDATE_DEFAULT_VALUE, { defaultValue: value });
  };

  return (
    <Grid container direction='row'>
      <Grid item>
        <Select
          a11yLabel="fieldType"
          dsOnChange={handleFieldTypeChange}
          label="Field Type"
          placeholder="Select Type"
          required
          value={field.fieldType}
        >
          {FIELD_TYPE_OPTIONS.map(({ value, label }) => (
            <SelectItem key={value} value={value}>
              {label}
            </SelectItem>
          ))}
        </Select>
      </Grid>
      
      <Grid item>
        <TextField 
          dsOnChange={handleFieldNameChange} 
          label='Name' 
          value={field.name} 
          required 
        />
      </Grid>

      <Grid item sx={{marginTop:'32px !important'}}> 
        {field.fieldType !== FieldTypes.CHECKBOX && (
          <Checkbox 
            dsOnChange={handleRequiredToggle} 
            label='Required' 
            checked={field.required} 
          />
        )}
      </Grid>

      <Grid item sx={{marginTop:'32px !important'}}> 
        <Checkbox 
          dsOnChange={handleReadonlyToggle} 
          label='Read-Only' 
          checked={field.readonly} 
        />
      </Grid>

      {(field.fieldType === FieldTypes.FIELD_MAPPING || field.fieldType === FieldTypes.READONLY_FIELD_MAPPING) && (
        <MappingFields
          sourceHeader={field.sourceHeader}
          targetHeader={field.targetHeader}
          rows={field.rows}
          location={location}
          isReadonly={field.fieldType === FieldTypes.READONLY_FIELD_MAPPING}
        />
      )}

      {field.fieldType === FieldTypes.BUTTON && (
        <Grid item>
          <TextField
            a11yLabel='onClickFunction'
            label='onClick'
            value={field.onClick || ''}
            dsOnChange={(event) => handleOnClickUpdate(event.target.value)}
            multiline
            rows={4}
            maxRows={5}
            wrap='hard'
          />
        </Grid>
      )}

      {allowsDefault.includes(field.fieldType) &&
        (<Grid item sx={{marginTop:'32px !important'}}> 
          <Checkbox dsOnChange={() => {setUseDefaultValue(!useDefaultValue); handleDefaultValueUpdate(null);}} label='Use Default Value' checked={useDefaultValue} />
        </Grid>)
      }
      {useDefaultValue && allowsDefault.includes(field.fieldType) && (
        <>
          {field.fieldType === FieldTypes.CHECKBOX? (
            <Grid item sx={{ marginTop: '32px !important' }}>
              <Checkbox
                dsOnChange={(event) => handleDefaultValueUpdate(event.target.value)}
                label="Default Checked"
                checked={field.defaultValue === 'true'}
              />
            </Grid>
          ) : (
            <Grid item>
              <TextField
                dsOnChange={(event) => handleDefaultValueUpdate(event.target.value)}
                label="Default Value"
                value={typeof field.defaultValue === 'string' ? field.defaultValue : ''}
              />
            </Grid>
          )}
        </>
      )}

      <Grid item sx={{marginTop:'24px !important'}}>
        <Button icon='remove_circle_outline' dsOnClick={handleDelete}>
          Delete Field
        </Button>
      </Grid>
    </Grid>
  );
};

export default FieldBlock;