import { AutoThemeProvider, Tabs, Tab, Button, Dropdown, DropdownItem, Spinner, Grid, Alert } from '@platform-ui/design-system';
import React, { Dispatch, useEffect, useState } from 'react';
import { Action, useStoreContext } from '../../../Store';
import { IntegrationAppFormState } from '../state';
import ServiceConfigurationTab from './Tabs/ServiceConfigurationTab';
import UIConfigurationTab from './Tabs/UIConfigurationTab';
import { ADD_LOCALE } from '../actionTypes';
import LocaleTab from './Tabs/LocaleTab';
import { AlertColor } from '@platform-ui/design-system/node_modules/@mui/material';
import Connect from '../../../Connect/Connect';

interface IntegrationAppFormProps {
  locales: string[],
  path: string,
  indexPath: string,
}

export const IntegrationAppForm = ({
  locales,
  path,
  indexPath,
}: IntegrationAppFormProps) => {
  const { state, dispatch } = useStoreContext() as { state: IntegrationAppFormState, dispatch: Dispatch<Action> };
  const [tabs, setTabs] = useState(0);
  const [tabData, setTabData] = useState(Object.keys(state.title_translations));
  const [selectedLocale, setSelectedLocale] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const availableLocales = locales.filter(locale => !tabData.includes(locale));
  let fetching = false
  // NOTE(Xander): Check if the path includes an app id, if it does, we're updating, not creating
  const editApp = /\d/.test(path);
  // Alert Consts
  const [isAlert, setIsAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertHeader, setAlertHeader] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedLocale) {
      setTabData([...tabData, selectedLocale]);
      dispatch({
        type: ADD_LOCALE,
        payload: {
          title_translations: {[selectedLocale]: {"title":""}},
          description_translations: {[selectedLocale]: {"long":"", "short":""}}
        }
      });
      setSelectedLocale('');
      closeMenu();
    }
  }, [selectedLocale, tabData]);

  function renderTab() {
    switch (tabs) {
      case 0:
        return (<ServiceConfigurationTab />)
      case 1:
        return (<UIConfigurationTab />)
      default:
        return (<LocaleTab locale={tabData[tabs-2]} />)
    }
  }

  const submitIntegrationApp = async (event) => {
    try {
      setSubmitting(true);
      const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
      const { modified, ...filteredState } = state;

      const formData = new FormData();
      for (const key in filteredState) {
        // Note(Duc): skip images and unchanged icon
        if ((key === 'images') || (key === 'icon' && !(filteredState[key] instanceof File))) {
          continue;
        }
        const stateValue = filteredState[key];
        const formValue = stateValue instanceof File ? stateValue : typeof stateValue === 'object' ? JSON.stringify(stateValue || {}) : stateValue;
        formData.append(`integration_app[${key}]`, formValue);
      }

      const options = {
        method: editApp ? 'PATCH' : 'POST',
        headers: new Headers({
          'X-CSRF-Token': csrf
        }),
        body: formData
      };
      const response = await window.fetch(path, options);
      if (!response.ok) throw Error(response.statusText);
      setAlertSeverity('success');
      setAlertBody(editApp ? 'App Updated Successfully' : 'App Created Successfully');
      setIsAlert(true);
      setSubmitting(false);
      window.location.href = indexPath;
    } catch (error) {
      Connect.log(error);
      setAlertSeverity('error');
      setAlertBody('App Not Saved');
      setIsAlert(true);
      setSubmitting(false);
    }
  }

  return (
    <AutoThemeProvider>
      {
        fetching ?
        <Spinner /> :
        <>
          <Grid container>
            <Grid item xs={4}>
              <h2>{editApp ? 'Edit Integration App' : 'New Integration App'}</h2>
            </Grid>
            <Grid item xs={4}>
              <Alert
                  body={alertBody}
                  dismissible
                  dsOnClose={() => {
                      setIsAlert(false);
                  }}
                  e2e="applicationAlert"
                  header={alertHeader}
                  open={isAlert}
                  severity={alertSeverity as AlertColor}
              />
            </Grid>
            <Grid item xs={4} sx={{textAlign: 'right'}}>
              <Button disabled={submitting || !state.modified} children="Submit" dsOnClick={submitIntegrationApp} />
            </Grid>
          </Grid>
          <Tabs
            a11yLabel="tab"
            value={tabs}
            dsOnChange={(_, newIndex) => { setTabs(newIndex) }}

            e2e="integration-apps-form-tabs"
          >
            <Tab
              id="service-configuration-tab"
              e2e="service-configuration"
              label="Service Configuration"
              aria-controls=""
            />
            <Tab
              id="ui-configuration-tab"
              e2e="ui-configuration"
              label="UI Configuration"
              aria-controls=""
            />
            {tabData.map((tabLabel, index) => (
              <Tab
              id={tabLabel}
              key={index}
              label={tabLabel}
              aria-controls="" />
            ))}
            {availableLocales.length > 0 && (
              <Button
                endIcon='add_circle_outline'
                dsOnClick={handleOpenDropdown}
              >
                New Locale
              </Button>
            )}
            <Dropdown
              a11yId='localeDropdown'
              open={!!anchorEl}
              anchorEl={anchorEl}
              dsOnClose={handleDropdownClose}
              e2e="admin-dropdown"
            >
            {availableLocales.map((option) => (
              <DropdownItem
                key={option}
                dsOnClick={() => setSelectedLocale(option)}
                e2e={option}
              >
                {option}
              </DropdownItem>
            ))}
          </Dropdown>
        </Tabs>
        {renderTab()}
      </>
    }
    </AutoThemeProvider>
  );
}

export default IntegrationAppForm;
